import { useContext, useEffect, useState } from "react";
import { Finder } from "../finder/Finder";
import { Nav, Navbar } from "react-bootstrap";
import {
    Menu,
    MenuList,
    MenuButton,
    MenuItem,
    MenuLink,
} from "@reach/menu-button";
import "@reach/menu-button/styles.css";
import "./Changes.css"
// import { pullRemote, pushRemote, pullLocal, pushLocal } from "../../../model/ActionFactory";
// import { Documents, Store } from "../../StoreComponent";
const emptyObject = { }
export function Changes() {
    // const store = useContext(Store)

    const [mode, setMode] = useState("changes" as "changes" | "remote")
    const [syncing, setSyncing] = useState(false)
    const [pulling, setPulling] = useState(false)
    const [pushing, setPushing] = useState(false)
    const [committing, setCommitting] = useState(false)

    const sync = async () => {
        setSyncing(true)
        try {
            // const remoteState = await pullRemote(store)
        } finally {
            setSyncing(false)
        }
    }
    const doPull = async () => {
        if (mode === 'remote') {
            // await pullRemote(store)
        }
    }
    const doPush = async () => {
        if (mode === 'remote') {
            // await pushRemote(store)
        }
    }
    const pull = async () => { setPulling(true); try { await doPull() } finally { setPulling(false) } }
    const push = async () => { setPushing(true); try { await doPush() } finally { setPushing(false) } }
    const commit = async () => { 
        // setCommitting(true)
        // try {
        //     const localState = await pullLocal(store)
        //     const remoteState = await pushLocal(store, localState)
        //     await pushRemote(store, remoteState)
        // } finally {
        //     setCommitting(false)
        // }
    }

    // const [input, setInput] = useState(emptyObject as Documents)

    useEffect(() => {

        // let result: Documents = emptyObject
        // if (mode === 'remote') {
        //     result = store.remote
        // } else if (mode === 'changes') {
        //     result = { }
        //     const localIds = (store.local.summary as any).ids as string[]
        //     const remoteIds = (store.remote.summary as any).ids as string[]
        //     const ids = Array.from(new Set([...Object.values(localIds), ...Object.values(remoteIds)])) as string[]

        //     console.log(ids)
        //     for (let id of ids) {
        //         if (id === 'workspace' || id === 'summary') continue
        //         if (!localIds.includes(id) || !remoteIds.includes(id)) {
                    
        //             if (localIds.includes(id)) {
        //                 console.log("DELETED remotely OR CREATED locally")
        //             } else {
        //                 console.log("CREATED remotely OR DELETED locally")
        //             }
        //         }
        //         const localDoc = store.local[id]
        //         const remoteDoc = store.remote[id]
        //         if (localDoc !== remoteDoc && !Automerge.equals(localDoc, remoteDoc)) {
        //             result[id] = store.local[id] ?? store.remote[id]
        //         }
        //     }
        // }
    
        // setInput(result ?? emptyObject)
    
    }, [/* store, store.local, store.remote,*/ mode])
    const input = { }
    return (
        <div className="changes">

            <Navbar expand={false} expanded={true} variant="dark">
                <span className="flex-fill" >{mode.substring(0, 1).toUpperCase() + mode.substring(1)}</span>
                
                {mode === 'changes' && <>

                    <button className="btn btn-menu" disabled={committing || Object.values(input).length === 0} onClick={commit}>
                        {committing ? <span className="spinner-border spinner-border-sm" role="status"></span> : (
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M14.4315 3.3232L5.96151 13.3232L5.1708 13.2874L1.8208 8.5174L2.63915 7.94268L5.61697 12.1827L13.6684 2.67688L14.4315 3.3232Z" fill="currentColor"/>
                            </svg>
                        )}
                    </button>

                    <button className="btn btn-menu" disabled={syncing} onClick={sync}>
                    {syncing ? <span className="spinner-border spinner-border-sm" role="status"></span> : (
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M5.56253 2.51577C3.46348 3.4501 2 5.55414 2 7.99999C2 11.3137 4.68629 14 8 14C11.3137 14 14 11.3137 14 7.99999C14 5.32519 12.2497 3.05919 9.83199 2.28482L9.52968 3.23832C11.5429 3.88454 13 5.7721 13 7.99999C13 10.7614 10.7614 13 8 13C5.23858 13 3 10.7614 3 7.99999C3 6.31104 3.83742 4.81767 5.11969 3.91245L5.56253 2.51577Z" fill="currentColor"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M5 3H2V2H5.5L6 2.5V6H5V3Z" fill="currentColor"/>
                        </svg>
                    )}
                    </button>

                </>}
                
                {mode === 'remote' && <>
                    <button className="btn btn-menu" disabled={pulling} onClick={pull}>
                        {pulling ? <span className="spinner-border spinner-border-sm" role="status"></span> : (
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M3.14642 9.00001L8.14641 14L8.85352 14L13.8535 9.00001L13.1464 8.2929L8.99997 12.4393L8.99997 2.00001L7.99997 2.00001L7.99997 12.4393L3.85353 8.2929L3.14642 9.00001Z" fill="currentColor"/>
                            </svg>

                        )}
                    </button>

                    <button className="btn btn-menu" disabled={pushing} onClick={push}>
                        {pushing ? <span className="spinner-border spinner-border-sm" role="status"></span> : (
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M13.8536 6.99999L8.85359 2H8.14648L3.14648 6.99999L3.85359 7.7071L8.00003 3.56066V14H9.00003V3.56066L13.1465 7.7071L13.8536 6.99999Z" fill="currentColor"/>
                            </svg>
                        )}
                    </button>
                </>}

                <Menu>
                    <MenuButton className="btn btn-menu btn-more">

                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M6 12C6 12.2967 5.91203 12.5867 5.74721 12.8334C5.58238 13.08 5.34812 13.2723 5.07403 13.3858C4.79994 13.4994 4.49834 13.5291 4.20737 13.4712C3.91639 13.4133 3.64912 13.2704 3.43934 13.0607C3.22956 12.8509 3.0867 12.5836 3.02882 12.2926C2.97094 12.0017 3.00065 11.7001 3.11418 11.426C3.22771 11.1519 3.41997 10.9176 3.66665 10.7528C3.91332 10.588 4.20333 10.5 4.5 10.5C4.89783 10.5 5.27936 10.658 5.56066 10.9393C5.84197 11.2206 6 11.6022 6 12Z" fill="currentColor" />
                            <path d="M13.5 12C13.5 12.2967 13.412 12.5867 13.2472 12.8334C13.0824 13.08 12.8481 13.2723 12.574 13.3858C12.2999 13.4994 11.9983 13.5291 11.7074 13.4712C11.4164 13.4133 11.1491 13.2704 10.9393 13.0607C10.7296 12.8509 10.5867 12.5836 10.5288 12.2926C10.4709 12.0017 10.5006 11.7001 10.6142 11.426C10.7277 11.1519 10.92 10.9176 11.1666 10.7528C11.4133 10.588 11.7033 10.5 12 10.5C12.3978 10.5 12.7794 10.658 13.0607 10.9393C13.342 11.2206 13.5 11.6022 13.5 12Z" fill="currentColor" />
                            <path d="M21 12C21 12.2967 20.912 12.5867 20.7472 12.8334C20.5824 13.08 20.3481 13.2723 20.074 13.3858C19.7999 13.4994 19.4983 13.5291 19.2074 13.4712C18.9164 13.4133 18.6491 13.2704 18.4393 13.0607C18.2296 12.8509 18.0867 12.5836 18.0288 12.2926C17.9709 12.0017 18.0006 11.7001 18.1142 11.426C18.2277 11.1519 18.42 10.9176 18.6666 10.7528C18.9133 10.588 19.2033 10.5 19.5 10.5C19.8978 10.5 20.2794 10.658 20.5607 10.9393C20.842 11.2206 21 11.6022 21 12Z" fill="currentColor" />
                        </svg>

                    </MenuButton>
                    <MenuList>
                        <MenuItem onSelect={() => { setMode("changes") }}>Show Changes</MenuItem>
                        <MenuItem onSelect={() => { setMode("remote") }}>Show Remote</MenuItem>
                    </MenuList>
                </Menu>
            </Navbar>

            <Finder mode={mode} />
        </div>
    )
}