import React, { useEffect, useRef, useState } from "react"
import { useContext } from "react"
import ReactDOM from 'react-dom'
// import { AutoSizer } from 'react-virtualized'
import { WasmContext, WasmContextType } from "../../../App"
import { CompositeModule } from "../../../model/Model"
import './ModuleEditor.css'

// Paint based editor:
// Problems: resizing flickers

// export class ModuleEditor extends React.Component<{id: string}> {
//     ref = React.createRef<HTMLCanvasElement>()
//     ctx?: CanvasRenderingContext2D = undefined
//     module?: typeof import("dsps-editor")

//     async componentDidMount() {
//         this.ctx = this.ref.current?.getContext("2d") ?? undefined
//         this.module = await import('dsps-editor')
//         this.request()
//     }
//     componentWillUnmount() {
//         this.ctx = undefined
//     }
//     request = () => {
//         if (!this.ctx) return
//         requestAnimationFrame(this.draw)
//         setTimeout(this.request, 100)
//     }
//     draw = () => {
//         if (!this.ctx || !this.module) return
//         this.module!.paintWeb(this.ctx)
//     }
//     render = () => <>
//         <AutoSizer>
//             {({width, height}) =>
//                 <canvas ref={this.ref} width={width} height={height} style={{width: "100%", height: "100%", backgroundColor: 'rgba(0,0,255,0.1)'}}></canvas>
//             }
//         </AutoSizer>
//     </>
// }


export function ModuleEditor(props: { id: string }) {
    const wasmContext = useContext(WasmContext)

    return <>
        {/* <TestModuleEditor /> */}
        {wasmContext && <ModuleEditorInner key={props.id} element_id={props.id} wasmContext={wasmContext} />}
    </>
}


class ModuleEditorInner extends React.Component<{ element_id: string, wasmContext: WasmContextType }> {

    element_id: string = this.props.element_id
    editor_id: number | undefined
    element: any
    observer = new ResizeObserver(() => {
        if (this.editor_id !== undefined) {
            this.props.wasmContext.editor.on_editor_resized(this.editor_id);
        }
    })

    componentDidUpdate() {
        if (this.props.element_id !== this.element_id) {
            throw "element_id must not change -> use as key on <ModuleEditorInner key={element_id} />"
        }
    }

    componentWillUnmount() {
        this.setElement(undefined);
    }

    shouldComponentUpdate() {
        return false;
    }

    setElement = (newElement: any) => {
        if (this.element !== newElement) {
            if (this.element) {
                this.observer.unobserve(this.element)
            }
            this.element = newElement
            if (this.element) {
                this.observer.observe(this.element);
            }

            if (newElement) {
                const x = ReactDOM.findDOMNode(this)
                this.editor_id = this.props.wasmContext.editor.editor_init(x as HTMLDivElement,  (id: number) => {
                    if (id == this.editor_id) {
                        this.forceUpdate()
                    }
                })
            }
        }
    }

    // svg positioning:
    // https://stackoverflow.com/questions/479591/svg-positioning
    // https://stackoverflow.com/questions/17098397/how-to-translate-an-svg-group-by-a-percentage-of-the-viewport
    render = () => (
        <div className="editor-container" ref={(ref) => this.setElement(ref)} />

        // <svg className="editor" style={{ minWidth: "100%", minHeight: "100%", width: 1000, backgroundColor: "rgb(0, 32, 64)" }}>
        //     <pattern id="pattern" x="50%" y="50%" width="8" height="8" transform="translate(150, 100)" patternUnits="userSpaceOnUse">
        //         <line x1="0" y1="0" x2="0" y2="16" stroke="white" />
        //         <line x1="0" y1="0" x2="16" y2="0" stroke="white" />
        //     </pattern>
        //     <rect width="100%" height="100%" fill="url(#pattern)" opacity="0.1" shapeRendering="crispEdges" />

        //     <rect x="25%" width="50%" y="100%" transform="translate(0, -10)" height="10" rx="4" ry="4" fill="white" opacity="0.5" shapeRendering="crispEdges" />
        //     <rect x="100%" height="50%" y="25%" transform="translate(-10, 0)" width="10" rx="4" ry="4" fill="white" opacity="0.5" shapeRendering="crispEdges" />

        //     <svg x="50%" y="50%" overflow="visible">
        //         <g transform="scale(0.5) translate(150, 100)" >
        //             <rect x="-32" y="-32" height="64" width="64" fill="red" />
        //         </g>
        //     </svg>
        // </svg>
    )

}


function TestModuleEditor() {

    let [zoom, setZoom] = useState(0);
    let [x, setX] = useState(0)
    let [y, setY] = useState(0)
    let ref = useRef<SVGSVGElement>(null)

    let scale = Math.pow(2.0, zoom);
    let scale16 = scale * 16

    let Xmod = ((x / scale16) - Math.floor(x / scale16)) * scale16;
    let Ymod = ((y / scale16) - Math.floor(y / scale16)) * scale16;


    function convertClientToLogical(x: number, y: number, clientWidth: number, clientHeight: number, translateX: number, translateY: number, scale: number) {

        // Move center point into the middle
        x = x - clientWidth / 2;
        y = y - clientHeight / 2;

        // Client Translation
        x = x - translateX;
        y = y - translateY;

        // Zoom
        x = x / scale;
        y = y / scale;

        return { x, y };
    }


    const onWheel = (event: React.WheelEvent) => {
        if (event.ctrlKey) {
            let newZoom = zoom - event.deltaY * 0.004
            let newScale = Math.pow(2.0, newZoom);

            const clientRect = ref.current!.getBoundingClientRect()
            const clientWidth = clientRect.width;
            const clientHeight = clientRect.height;

            const clientX = event.clientX - clientRect.left
            const clientY = event.clientY - clientRect.top
            const logical = convertClientToLogical(clientX, clientY, clientWidth, clientHeight, 0, 0, scale);

            const oldZoomFactor = scale;
            const zoomFactor = newScale;

            setX(x - logical.x * (zoomFactor - oldZoomFactor))
            setY(y - logical.y * (zoomFactor - oldZoomFactor))
            setZoom(newZoom)
        } else {
            setX(x - event.deltaX)
            setY(y - event.deltaY)
        }
    }

    return (
        <div className="editor" onWheel={onWheel}>
            <div>
                <svg className="simple-diagram" ref={ref} transform={`scale(${scale}) translate(${x / scale}, ${y / scale})`}>
 
                    <rect x="0" y="0" height="64" width="64" fill="red" />
                    <text x="0" y="32" className="complex">module</text>

                </svg>
            </div>
        </div>
    )
}