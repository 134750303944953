import localforage from 'localforage'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import DatastoreWorker from './workers/Datastore.worker.js'; //Uses the worker-loader external lib

ReactDOM.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
  ,
  document.getElementById('root')
);

const shouldClone = false

async function main() {
 
  const gitWorker = new Worker('/git.worker.js')

  function git(message: any | null = null, tester: (response: any) => boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        gitWorker.onmessage = result => {
          if (tester(result.data)) resolve(result.data);
        };
        gitWorker.onerror = error => {
          reject(error);
        };
        if (message !== null) {
          gitWorker.postMessage(message);
        }
      } catch (error) {
        reject(error);
      }
    });
  }
  console.log(await git(null, (res) => res.ready))

  async function clone() {
    console.log(await git({command: "synclocal", url: "https://github.com/raghavavemireddy/empty-project1.git"}, (res) => res.synclocal))
    console.log(await git({command: "clone", url: "https://github.com/raghavavemireddy/empty-project1.git"}, (res) => res.clone))
  }
  if (shouldClone) {
    await clone()
  }

  const getWorkspaceFiles = async () => {
    const prefix = workspace + "/"
    return (await db.keys()).filter(file => file.startsWith(prefix) && !file.substring(prefix.length).startsWith(".git/")) 
  }


  async function idbChanged(file: string, contents: Uint8Array) {
    const result = editor.hash(contents)
    var hash = new TextDecoder().decode(result);
    // write hash of each file into localstorage -> takes not much space and has good cross-tab observer support
    if (localStorage.getItem(file) !== hash) {
      localStorage.setItem(file, hash);
      publishChange(file, hash, contents)
    }
  }

  const workspace = '/empty-project1.git'
  const editor = await import("dsps-editor")
  editor.init_once()

  let db = localforage.createInstance({name: workspace, storeName: "FILE_DATA"})
  
  async function detectChanges() {
    const files = await getWorkspaceFiles()
    for (let file of files) {
      const data = await db.getItem(file) as any
      const contents = data?.contents
      if (contents) {
        idbChanged(file, contents)
      }
    }
  }
  detectChanges()

  function publishChange(key: string, hash: string | null, data: Uint8Array) {
    var string = new TextDecoder().decode(data);
    console.log(key, " changed: ", string, "hash: ", hash)
    if (hash) {
      console.log("CALLED STORE_SAVE")
      editor.filestore_save('/empty-project1.git/filetext.txt', hash, data)
    }
  }

  window.onstorage = async (event) => {
    if (event.key) {
      const filename = event.key.substring(event.key.indexOf('/'))
      const data = await db.getItem(filename) as any
      const contents = data?.contents
      publishChange(filename, event.newValue, contents)
    }
  };

  async function init() {
    const files = await getWorkspaceFiles()
    for (let file of files) {
      const data = await db.getItem(file) as any
      const contents = data?.contents
      if (contents) {
        publishChange(file, localStorage.getItem(file), contents)
      }
    }
  }
  await init()
}
main()


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
