
/* Idea: Define the model in rust using serde and generate ts-interfaces from that */

import { IJsonModel } from 'flexlayout-react'

export type TopLevelElement = CompositeModule

export interface WorkspaceData {
  eType: 'WorkspaceData'
  layoutData: LayoutData
}

export interface RepositoryData {
  // documents: Automerge.Table<TopLevelElement>
}

export interface Summary {
  eType: 'Summary', ids: string[]
}
export interface Modules {
  [id: string]: Module
}

export type Module = PrimitiveModule | CompositeModule

export interface BaseModule {
  id: string
  name: string | null
  category: string
}

export interface PrimitiveModule extends BaseModule {
  eType: 'Module'
}
export interface CompositeModule extends BaseModule {
  eType: 'CompositeModule'
  data: any
}

export interface LayoutData {
  layout: IJsonModel['layout']
  sidebar: {
    width: number,
    selectedTab: number | null
  }
}

export const defaultSidebarWidth = 200

export const initialWorkspaceData: WorkspaceData = {
  eType: 'WorkspaceData',
  layoutData: {
    layout: {
      type: "row",
      children: [
        {
          type: "tabset",
          weight: 100,
          selected: 0,
          // enableDeleteWhenEmpty: false,
          children: [
            {
                type: "tab",
                name: "Module",
                component: "editor",
                config: {
                    id: "0",
                    mode: false
                }
            
            }

          ]
        }
      ]
    },
    sidebar: {
      selectedTab: 0,
      width: defaultSidebarWidth
    }
  }

}