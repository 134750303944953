import { useContext, useEffect, useState } from 'react';
// import Tree, { Node } from 'react-virtualized-tree';
// import { openEditor } from '../../../model/ActionFactory';
import { Modules, TopLevelElement } from '../../../model/Model';
import './Finder.css';
// import { Expandable }n from './renderers/Expandable';

const emptyObject = { }

export function Finder(props: {mode: 'local' | 'remote' | 'changes'}) {
    // const store = useContext(Store);
    const [state, setState] = useState([] as Node[])

    useEffect(() => {

        const modules: Modules = { };

        // Object.keys(props.input).forEach(id => {
        //     const doc = props.input[id]
        //     const element = doc.documents.byId(id)
        //     if (element.eType === 'CompositeModule') {
        //         modules[id] = element
        //     }
        // })

        const groups: any = {
        };
        Object.values(modules).forEach(value => {
            if (groups[value.category] === undefined) {
                groups[value.category] = []
            }
            groups[value.category].push(value.category)
        })
        
        const nodes = Object.keys(groups).reduce((wc: any, g) => {
            const categories = groups[g];
        
            const category = {
                id: Math.random(),
                name: `${g}`,
                state: {
                    expanded: true,
                },
                children: Object.keys(modules).map((gc: any) => ({
                    id: gc,
                    name: modules[gc].name,
                })),
            };
        
            return [...wc, category];
        }, []);

        setState(nodes)
        
    }, [/*props.input, */ props.mode])

    return         <div className="finder" data-mode={props.mode}> </div>

    // return (
    //     <div className="finder" data-mode={props.mode}>
    //         <Tree nodes={state} onChange={setState}>
    //             {({ style, node, ...rest }) => {
    //                 return (
    //                     <div style={style} onDoubleClick={() => /*openEditor(store, `${node.id}`, props.mode)*/{} }>
    //                         <Expandable node={node} {...rest}>
    //                             <span
    //                                 style={{
    //                                     fontWeight: 'normal',
    //                                 }}
    //                             >
    //                                 {node.name}
    //                             </span>
    //                         </Expandable>
    //                     </div>
    //                 );
    //             }}
    //         </Tree>
    //     </div>
    // )
}
