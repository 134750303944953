import { Button, Modal, Nav } from "react-bootstrap";
import './WorkspaceList.css'
import { Link, useHistory } from 'react-router-dom';
import classnames from 'classnames';
import { useEffect, useState } from "react";
// import localForage from "localforage";
import { ListGroup } from "react-bootstrap";
import { useInterval } from "../utils";
import axios from 'axios'

const plural = (amount: number, singular: string, plural: string, digits?: number) => '' + amount.toFixed(digits || 0) + " " + (amount === 1 ? singular : plural);
const memorySize = (bytes: number) => {
    const KB = 1024;
    const MB = KB * KB;
    if (bytes > MB) {
        return (bytes / MB).toFixed(1) + " MB";
    } else if (bytes > KB) {
        return (bytes / KB).toFixed(1) + " KB";
    } else {
        return bytes.toFixed(0) + " B"
    }
}

const timeInterval = (ms: number) => {
    const seconds = ms / 1000;
    const minutes = seconds / 60;
    const hours = minutes / 60;
    const days = hours / 24;
    const years = days / 365;
    if (years >= 1) {
        return plural(years, "year", "years") + " ago";
    } else if (days >= 1) {
        return plural(days, "day", "days") + " ago";
    } else if (hours >= 1) {
        return plural(hours, "hour", "hours") + " ago";
    } else if (minutes >= 1) {
        return plural(minutes, "minute", "minutes") + " ago";
    } else if (seconds >= 1) {
        return plural(seconds, "second", "seconds") + " ago";
    }
    return "just now"
}

export interface WorkspaceInfo {
    name: string
    lastAccessTimestamp: number
    numModules: number
    numTables: number
    numRepositories: number
    numBytes: number
}
export interface WorkspaceInfos {
    [name: string]: WorkspaceInfo
}
const onDeleteWorkspace = (workspace: string) => (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();


    const doDelete = async () => {

        // await localForage.dropInstance({
        //     name: `ws_${workspace}`
        // })

        // const store = localForage.createInstance({ name: "meta_workspaceinfo" });
        // const workspaces: WorkspaceInfos = await store.getItem("workspaces") ?? { }
        // delete workspaces[workspace]
        // await store.setItem("workspaces", workspaces)

        // window.location.reload()
    }
    doDelete()
}

const workspaceUrl = (version: string, isRemote: boolean, name: string) => `/${version}/${isRemote ? 'workspace' : 'local'}/${name}`

export const WorkspaceList = (props: { version: string, workspace: string | undefined, isRemote: boolean }) => {
    // const store = localForage.createInstance({ name: "meta_workspaceinfo" });

    const version = props.version ?? "latest"
    const currentWorkspace = props.workspace ?? ""
    const isRemote = props.isRemote
    const list = props.isRemote ? "cloud" : "list"

    const [workspaces, setWorkspaces] = useState(undefined as WorkspaceInfos | undefined)

    async function update() {
        // setWorkspaces(await store.getItem("workspaces") ?? {} as WorkspaceInfos)
        setWorkspaces({})
    }
    useEffect(() => { update() }, [])

    useInterval(() => {
        update()
    }, 1000)

    const history = useHistory();

    return (
        <div id="workspace-list">
            <Modal.Dialog centered>
                <Modal.Header>
                    <Modal.Title>Login</Modal.Title>
                    <Nav variant="tabs" className="me-2">
                        <Nav.Item>
                            <Link className={classnames({ "nav-link": true, "active": !isRemote })} to={`/${version}/list/${currentWorkspace}`}>
                                Local
                            </Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Link className={classnames({ "nav-link": true, "active": isRemote })} to={`/${version}/cloud/${currentWorkspace}`}>
                                <svg style={{ height: '1em', marginRight: 6, marginBottom: 0, marginTop: -2, marginLeft: -6, display: 'inline', position: 'relative' }} viewBox="0 0 640 512"><path fill="currentColor" d="M537.6 226.6c4.1-10.7 6.4-22.4 6.4-34.6 0-53-43-96-96-96-19.7 0-38.1 6-53.3 16.2C367 64.2 315.3 32 256 32c-88.4 0-160 71.6-160 160 0 2.7.1 5.4.2 8.1C40.2 219.8 0 273.2 0 336c0 79.5 64.5 144 144 144h368c70.7 0 128-57.3 128-128 0-61.9-44-113.6-102.4-125.4z"></path></svg>
                                Cloud
                            </Link>
                        </Nav.Item>
                    </Nav>
                </Modal.Header>

                <Modal.Body>
                    <div className="content container">
                        <h5 className="mr-auto mt-auto">Workspaces:</h5>
                        {!workspaces && <div className="d-flex justify-content-center"><div className="spinner-border text-primary" role="status"><span className="visually-hidden">Loading...</span></div></div>}
                        {workspaces && <ListGroup>
                            {Object.values(workspaces).map(workspace => {
                                const active = workspace.name === props.workspace
                                const details = [
                                    workspace.numRepositories && plural(workspace.numRepositories, "repository", "repositories"),
                                    workspace.numModules && plural(workspace.numModules, "module", "modules"),
                                    workspace.numTables && plural(workspace.numTables, "table", "tables")
                                ].filter(x => !!x);
                                const last = details.length > 1 ? details.pop() : undefined;
                                const stats = details.join(", ") + (last === undefined ? "" : " and " + last);
                                return (
                                    <Link key={workspace.name} draggable={false} className={classnames({ "list-group-item list-group-item-action ": true, "active": active })} to={`/${version}/${list}/${workspace.name}`} onDoubleClick={() => { history.push(workspaceUrl(version, props.isRemote, workspace.name)) }}>
                                        <div className="d-flex w-100 justify-content-between">
                                            <h5 className="mb-1">{workspace.name}</h5>
                                            {workspace.lastAccessTimestamp !== undefined && <small>modified {timeInterval(+new Date() - workspace.lastAccessTimestamp)}</small>}
                                        </div>
                                        <button type="button" title="Delete Workspace" className="btn btn-danger delete-button" onMouseDown={(e) => { e.stopPropagation() }} onClick={onDeleteWorkspace(workspace.name)} onDoubleClick={(e) => { e.preventDefault(); e.stopPropagation() }}>
                                            <svg viewBox="0 0 448 512" style={{ width: 16, height: 16 }}><path fill="currentColor" d="M268 416h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12zM432 80h-82.41l-34-56.7A48 48 0 0 0 274.41 0H173.59a48 48 0 0 0-41.16 23.3L98.41 80H16A16 16 0 0 0 0 96v16a16 16 0 0 0 16 16h16v336a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128h16a16 16 0 0 0 16-16V96a16 16 0 0 0-16-16zM171.84 50.91A6 6 0 0 1 177 48h94a6 6 0 0 1 5.15 2.91L293.61 80H154.39zM368 464H80V128h288zm-212-48h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12z"></path></svg>
                                        </button>
                                        <p className="mb-1">Contains: {stats}</p>
                                        {workspace.numBytes && <small>{memorySize(parseInt('' + workspace.numBytes))}</small>}
                                    </Link>
                                )
                            })}
                            <div className="list-group-item">
                                <div className="d-flex w-100 justify-content-between">
                                    {Object.values(workspaces).length === 0 && <h5 className="mb-1">You have no workspaces created yet.</h5>}
                                </div>
                            </div>
                        </ListGroup>
                        }
                    </div>
                </Modal.Body>

                <Modal.Footer>

                    <Button variant="primary" onClick={() => {
                        history.push(workspaceUrl(version, props.isRemote, currentWorkspace))
                    }}>Enter Workspace</Button>

                </Modal.Footer>
            </Modal.Dialog>
        </div>
    )
}