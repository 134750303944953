import React from 'react';
import './App.scss';
import { Workspace } from './components/Workspace';
import { BrowserRouter as Router, Switch, Route, Link, useRouteMatch, useParams } from "react-router-dom";
import { WorkspaceList } from './components/WorkspaceList';
import { useEffect } from 'react';
import { render } from '@testing-library/react';


function WorkspaceRoute(props: {isRemote: boolean}) {
  let { workspaceId } = useParams() as any
  return (
    // <StoreComponent key={workspaceId + props.isRemote} workspaceId={workspaceId} isRemote={props.isRemote}>
      <Workspace isRemote={props.isRemote} />
    // </StoreComponent>
  )
}

function SelectedListRoute(props: {isRemote: boolean}) {
  let { workspaceId, version } = useParams() as any
  return <WorkspaceList workspace={workspaceId} version={version} isRemote={props.isRemote} />
}

function ListRoute(props: {isRemote: boolean}) {
  let match = useRouteMatch();

  let { version } = useParams() as any
  return (
    <Switch>
      <Route path={`${match.path}/:workspaceId`}>
        <SelectedListRoute isRemote={props.isRemote} />
      </Route>
      <Route path={match.path}>
        <WorkspaceList workspace={undefined} version={version} isRemote={props.isRemote} />
      </Route>
    </Switch>
  )

}



export interface WasmContextType {
  editor: typeof import('dsps-editor')
}
export const WasmContext = React.createContext(undefined as WasmContextType | undefined);

class WasmProvider extends React.Component<{ children: any }, WasmContextType> {
  timer: any
  async componentDidMount() {
    const editor = await import('dsps-editor')
    editor.init_once()
    this.setState({editor})
    // this.timer = setInterval(async () => {
    //   const editor = await import('dsps-editor')
    //   console.log(editor.version())
    //   if (editor !== this.state.editor) {
    //     this.setState({editor})
    //     console.log("APPLYING")
    //   }
    // }, 1000)
  }
  componentWillUnmount() {
    clearInterval(this.timer)
    this.timer = undefined
  }
  render() {
    return <WasmContext.Provider value={this.state}>
      {this.props.children}
    </WasmContext.Provider>
  }
}

function App() {
  return (
    <WasmProvider>
      <Router>
        <Switch>
          <Route path="/:version/local/:workspaceId">
            <WorkspaceRoute isRemote={false} />
          </Route>
          <Route path="/:version/workspace/:workspaceId">
            <WorkspaceRoute isRemote={true} />
          </Route>
          <Route path="/:version/list">
            <ListRoute isRemote={false} />
          </Route>
          <Route path="/:version/cloud">
            <ListRoute isRemote={true} />
          </Route>
          <Route>
            <ListRoute isRemote={true} />
          </Route>
        </Switch>
      </Router>
    </WasmProvider>
  );
}

export default App;
