import React from 'react';
import { initialWorkspaceData } from '../model/Model';
import { FlexLayout } from './flexlayout/FlexLayout';
import { Toolbar } from './Toolbar'

export const Workspace = (props: {isRemote: boolean}) => (
    <>
      <Toolbar />
      <FlexLayout layoutData={initialWorkspaceData.layoutData} isRemote={props.isRemote} dispatch={async (model) => {
        // const action: ModifyLayoutAction = {
        //   type: 'ModifyLayoutAction',
        //   layoutData: model
        // }
        // return store.dispatch(action)
        return undefined
      }}
      />
    </>
)