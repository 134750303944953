import { Nav, Navbar } from "react-bootstrap";
import './Toolbar.css'

import {
    Menu,
    MenuList,
    MenuButton,
    MenuItem,
    MenuLink,
} from "@reach/menu-button";
import "@reach/menu-button/styles.css";
import { useContext } from "react";
import { CompositeModule } from "../model/Model";
// import { __importFromServer } from "../model/ActionFactory";

export function Toolbar() {
    const createModule = () => {
        // soundengine.greet("XX")
        // const compositeModule: CompositeModule = {
        //     eType: 'CompositeModule',
        //     id: Math.random().toString(),
        //     category: "core",
        //     name: "Module " + (Object.values(store.local).length),
        //     data: {}
        // }
        // const action: ModifyDocumentAction = { 
        //     type: "ModifyDocumentAction",
        //     id: compositeModule.id,
        //     newData: Automerge.from(compositeModule)
        // }
        // store.dispatch(action)
    }

    return (
        <Navbar expand={false} expanded={true} variant="dark" className="toolbar">
            <Menu>
                <MenuButton className="btn btn-menu">
                    <svg viewBox="0 0 10 7" width="14" height="10"><path fillRule="evenodd" clipRule="evenodd" d="M7.5 5.806H0v.944h7.5v-.944zm2.25-2.903H0v.944h9.75v-.944zM0 0h9.75v.944H0V0z" fill="currentColor"></path></svg>
                </MenuButton>
                <MenuList>
                    <MenuItem onSelect={createModule}>New Module</MenuItem>
                    <MenuItem onSelect={() => { }}>Import from Server</MenuItem>
                </MenuList>
            </Menu>
        </Navbar>
    )
}


// function Example() {
//   return (
//     <div>

//       <Menu>
//         <MenuButton id="links-button">
//           Links{" "}
//           <span aria-hidden="true" style={{ userSelect: "none" }}>
//             ▾
//           </span>
//         </MenuButton>
//         <MenuList>
//           <MenuLink href="https://google.com">Google</MenuLink>
//           <MenuLink href="https://duckduckgo.com">Duck Duck Go</MenuLink>
//         </MenuList>
//       </Menu>
//     </div>
//   );
// }
